
<template>
   <div class="row profile-view">
    <div class="col-lg-12">
      <div class="iq-card">
        <div class="iq-card-body p-0 pic-sec">
          <div class="iq-edit-list">
            <ul class="iq-edit-profile d-flex nav nav-pills">
              <li class="col-md-2 p-0"> <a class="nav-link active" data-toggle="pill" href="#general" @click="filterFun('General')"> General</a> </li>
              <li class="col-md-2 p-0"> <a class="nav-link" data-toggle="pill" href="#health" @click="filterFun('Health')"> Health </a> </li>
              <li class="col-md-2 p-0"> <a class="nav-link" data-toggle="pill" href="#travel" @click="filterFun('Travel')">  Travel </a> </li>
              <li class="col-md-2 p-0"> <a class="nav-link" data-toggle="pill" href="#cooking" @click="filterFun('Cooking')"> Cooking</a> </li>
              <li class="col-md-2 p-0"> <a class="nav-link" data-toggle="pill" href="#movies" @click="filterFun('Movies')"> Movies and Shows</a> </li>
              <li class="col-md-2 p-0"> <a class="nav-link" data-toggle="pill" href="#books" @click="filterFun('Books')">  Books </a> </li>
          </ul>
          </div>
        </div>
      </div>
    </div>
    
    <div class="col-lg-12 mb-3 sm-px-3">
        <div class="tab-content sm-p-0">
          <AddSocialPost @addPost="addPost"></AddSocialPost>
        <div class="tab-pane fade active show" id="general" role="tabpanel">         
            <b-row class="p-0">
              <b-col lg="8" class="sm-p-0">        
                  
                    <div  v-for="post in socialPosts" :key="post.id">
                      <social-post :post="post" :deletePostApi='deletePostApi' :deleteCommentApi='deleteCommentApi'></social-post>
                    </div>
                    <p v-if="allLoadError" class="text-center mx-1">{{allLoadError}}</p>
                    <span v-else><infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading></span>
                
              </b-col>
              <b-col lg="4" class="sm-p-0">          
              <recent-birthdate/>
              <!-- <iq-card :style="webanner" class="web-banner">
                <template v-slot:headerTitle>
                </template>
              </iq-card> -->
               <events/>
              
              </b-col>
            </b-row>
              </div>
        <div class="tab-pane fade" id="health" role="tabpanel">
            <b-row class="p-0">
        <b-col lg="8" class="sm-p-0">        
             <!-- <AddSocialPost @addPost="addPost"></AddSocialPost> -->
              <div  v-for="post in socialPosts" :key="post.id">
                <social-post :post="post" :deletePostApi='deletePostApi' :deleteCommentApi='deleteCommentApi'></social-post>
              </div>
              <p v-if="allLoadError" class="text-center mx-1">{{allLoadError}}</p>
              <span v-else><infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading></span>
                
          
        </b-col>
        <b-col lg="4" class="sm-p-0">          
         <recent-birthdate/>
          <!-- <iq-card :style="webanner" class="web-banner">
            <template v-slot:headerTitle>
            </template>
          </iq-card> -->
          <events/>
     
        </b-col>
      </b-row>    
          </div>         
        <div class="tab-pane fade" id="travel" role="tabpanel">
               <b-row class="p-0">
        <b-col lg="8" class="sm-p-0">        
             <!-- <AddSocialPost @addPost="addPost"></AddSocialPost> -->
              <div  v-for="post in socialPosts" :key="post.id">
                <social-post :post="post" :deletePostApi='deletePostApi' :deleteCommentApi='deleteCommentApi'></social-post>
              </div>
               <p v-if="allLoadError" class="text-center mx-1">{{allLoadError}}</p>
                <span v-else><infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading></span>
          
        </b-col>
        <b-col lg="4" class="sm-p-0">          
            <recent-birthdate/>
          <!-- <iq-card :style="webanner" class="web-banner">
            <template v-slot:headerTitle>
            </template>
          </iq-card> -->
         <events/>
   
        </b-col>
      </b-row>
           
          </div>  
        <div class="tab-pane fade" id="cooking" role="tabpanel">
             <b-row class=" p-0">
        <b-col lg="8" class="sm-p-0">        
             <!-- <AddSocialPost @addPost="addPost"></AddSocialPost> -->
              <div  v-for="post in socialPosts" :key="post.id">
                <social-post :post="post" :deletePostApi='deletePostApi' :deleteCommentApi='deleteCommentApi'></social-post>
              </div>
               <p v-if="allLoadError" class="text-center mx-1">{{allLoadError}}</p>
                <span v-else><infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading></span>
          
        </b-col>
        <b-col lg="4" class="sm-p-0">          
          <recent-birthdate/>
          <!-- <iq-card :style="webanner" class="web-banner">
            <template v-slot:headerTitle>
            </template>
          </iq-card> -->
         <events/>
      
        </b-col>
      </b-row>
             
          </div>  
        <div class="tab-pane fade" id="movies" role="tabpanel">

             <b-row class="p-0">
        <b-col lg="8" class="sm-p-0">        
             <!-- <AddSocialPost @addPost="addPost"></AddSocialPost> -->
              <div  v-for="post in socialPosts" :key="post.id">
                <social-post :post="post" :deletePostApi='deletePostApi' :deleteCommentApi='deleteCommentApi'></social-post>
              </div>
               <p v-if="allLoadError" class="text-center mx-1">{{allLoadError}}</p>
                <span v-else><infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading></span>
          
        </b-col>
        <b-col lg="4" class="sm-p-0">          
          <recent-birthdate/>
          <!-- <iq-card :style="webanner" class="web-banner">
            <template v-slot:headerTitle>
            </template>
          </iq-card> -->
          <events/>
       
        </b-col>
      </b-row>
           
        
          </div>  
        <div class="tab-pane fade" id="books" role="tabpanel">

             <b-row class=" p-0">
        <b-col lg="8" class="sm-p-0">        
             <!-- <AddSocialPost @addPost="addPost"></AddSocialPost> -->
              <div  v-for="post in socialPosts" :key="post.id">
                <social-post :post="post" :deletePostApi='deletePostApi' :deleteCommentApi='deleteCommentApi'></social-post>
              </div>
               <p v-if="allLoadError" class="text-center mx-1">{{allLoadError}}</p>
                <span v-else><infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading></span>
          
        </b-col>
        <b-col lg="4" class="sm-p-0">          
             <recent-birthdate/>
          <!-- <iq-card :style="webanner" class="web-banner">
            <template v-slot:headerTitle>
            </template>
          </iq-card> -->
          <events/>
  
        </b-col>
      </b-row>
   
          </div>  
        </div>     
    </div>
 
  </div>


 
</template>
<script>
import { socialvue } from '../../../config/pluginInit'
import ProfileImage from './ProfileImage'
import About from './ProfileFriends/About'
import AddSocialPost from '../../Apps/Social/Components/AddSocialPost'
import { Posts } from '../../../FackApi/api/SocialPost'
import SocialPost from '../../Apps/Social/Components/SocialPost'
import axios from '../../../components/axios'
import InfiniteLoading from 'vue-infinite-loading';
import RecentBirthdate from '../Bithdate/RecentBirthdate'
import Events from '../../Apps/Social/Components/Events'
export default {
  name: 'Tips',
   mounted () {
    socialvue.index()
    
    axios.defaults.headers["Authorization"] = 
    "Bearer " + localStorage.getItem("token");


  },
  components: {
    ProfileImage,
    AddSocialPost,
    SocialPost,
    About,
    InfiniteLoading,
    RecentBirthdate,
    Events
  },
   data () {
    return {
      daybanner: {
          backgroundImage: `url(${require('@/assets/images/dashboard-graphic2.png')})`
        },
       webanner: {
          backgroundImage: `url(${require('@/assets/images/dashboard-graphic1.png')})`
        },
      suggestionEvent: [
        {
          img: require('../../../assets/images/page-img/42.png'),
          title: 'Iqonic Studio',
          des: 'Lorem Ipsum',
          otherImg: require('../../../assets/images/small/img-1.jpg')
        },
        {
          img: require('../../../assets/images/page-img/43.png'),
          title: 'Cakes & Bakes ',
          des: 'Lorem Ipsum',
          otherImg: require('../../../assets/images/small/img-2.jpg')
        }
      ],
      bdaybanner: [
        {
          img: require('../../../assets/images/dashboard-graphic2.png'),
          heading: 'birthday banner'
        },
        {
          img: require('../../../assets/images/dashboard-graphic1.png'),
          heading: 'wfd'
        }
      ],
      // event: [
      //   {
      //     img: require('../../../assets/images/page-img/s4.jpg'),
      //     heading: 'Web Workshop',
      //     time: '1 hour ago'
      //   },
      //   {
      //     img: require('../../../assets/images/page-img/s5.jpg'),
      //     heading: 'Fun Events and Festivals',
      //     time: '4 hour ago'
      //   }
      // ],
      
      user: {
        background: require('../../../assets/images/page-img/profile-bg.jpg'),
        profile: require('../../../assets/images/user/user-11.png'),
        name: 'Nik Jone',
        role: ' - Web designer',
        about: 'Lorem ipsum dolor sit amet, contur adipiscing elit.',
        email: 'nikjone@demoo.com',
        phone: '001 2351 256 12',
        location: 'USA',
        careated_date: '07 Jan 2020'
      },
      socialPosts: [],
      page:1,
      last_page:1,
      allLoadError:'',
      cat:'General',
      infiniteId: +new Date(),
      galary: [
        { image: require('../../../assets/images/page-img/g1.jpg'), href: 'javascript:void(0);' },
        { image: require('../../../assets/images/page-img/g2.jpg'), href: 'javascript:void(0);' },
        { image: require('../../../assets/images/page-img/g3.jpg'), href: 'javascript:void(0);' },
        { image: require('../../../assets/images/page-img/g4.jpg'), href: 'javascript:void(0);' },
        { image: require('../../../assets/images/page-img/g5.jpg'), href: 'javascript:void(0);' },
        { image: require('../../../assets/images/page-img/g6.jpg'), href: 'javascript:void(0);' },
        { image: require('../../../assets/images/page-img/g7.jpg'), href: 'javascript:void(0);' },
        { image: require('../../../assets/images/page-img/g8.jpg'), href: 'javascript:void(0);' },
        { image: require('../../../assets/images/page-img/g9.jpg'), href: 'javascript:void(0);' }
      ],
      action: [
        {
          icon: 'ri-eye-fill mr-2',
          title: 'View'
        },
        {
          icon: 'ri-delete-bin-6-fill mr-2',
          title: 'Delete'
        },
        {
          icon: 'ri-pencil-fill mr-2',
          title: 'Edit'
        },
        {
          icon: 'ri-printer-fill mr-2',
          title: 'Print'
        },
        {
          icon: 'ri-file-download-fill mr-2',
          title: 'Download'
        }
      ],
      timelineItems: [
        {
          color: 'primary',
          title: 'Client Login',
          right: '24 November 2019',
          description: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          child: {
            type: 'img',
            items: [
            ]
          }
        },
        {
          color: 'success',
          title: 'Scheduled Maintenance',
          right: '23 November 2019',
          description: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          child: {
            type: 'img',
            items: [
            ]
          }
        },
        {
          color: 'danger',
          title: 'Dev Meetup',
          right: '20 November 2019',
          description: 'Bonbon macaroon jelly <b-link href="">beans gummi</b-link> bears jelly lollipop apple',
          child: {
            type: 'img',
            items: [
              require('../../../assets/images/user/user-05.jpg'),
              require('../../../assets/images/user/user-06.jpg'),
              require('../../../assets/images/user/user-07.jpg'),
              require('../../../assets/images/user/user-08.jpg'),
              require('../../../assets/images/user/user-09.jpg'),
              require('../../../assets/images/user/user10.jpg')
            ]
          }
        },
        {
          color: 'primary',
          title: 'Client Call',
          right: '19 November 2019',
          description: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          child: {
            type: 'img',
            items: [
            ]
          }
        },
        {
          color: 'warning',
          title: 'Mega Event',
          right: '15 November 2019',
          description: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          child: {
            type: 'img',
            items: [
            ]
          }
        }
      ],
      friends: [
        { name: 'Paul Molive', role: 'Web Designer', image: require('../../../assets/images/user/user-01.jpg') },
        { name: 'Paul Molive', role: 'trainee', image: require('../../../assets/images/user/user-01.jpg') }
      ],
      userBio: [
        { title: 'Joined', description: 'November 15, 2012' },
        { title: 'Lives', description: 'United States of America' },
        { title: 'Email', description: '<a href="mailto:nikjone@gmail.com"> nikjone@gmail.com</a>' },
        { title: 'Url', description: '<a href="https://getbootstrap.com/docs/4.0/getting-started/introduction/" target="_blank"> www.bootstrap.com </a>' },
        { title: 'Contact', description: '<a href="tel:001 4544 565 456">(001) 4544 565 456</a>' }
      ],
      story: [
        { title: 'Web Design', time: '1 hour ago', image: require('../../../assets/images/page-img/s1.jpg'), class: 'mb-4', isActive: 'active' },
        { title: 'App Design', time: '4 hour ago', image: require('../../../assets/images/page-img/s2.jpg'), class: 'mb-4', isActive: '' },
        { title: 'Abstract Design', time: '9 hour ago', image: require('../../../assets/images/page-img/s3.jpg'), class: '', isActive: '' }
      ],
      suggestions: [
        { name: 'Paul Molive', mutual_friend: 'Today', image: require('../../../assets/images/user/user-01.jpg') },
        { name: 'Paul Molive', mutual_friend: 'Tomorrow', image: require('../../../assets/images/user/user-01.jpg') }
      ],
      news: [
        { description: 'there is a meetup in your city on friday at 19:00.<a href="#">see details</a>' },
        { description: '20% off coupon on selected items at pharmaprix' }
      ],
      twitterFeed: [
        {
          image: require('../../../assets/images/page-img/42.jpg'),
          name: 'UI/Ux Designer',
          description: 'Creativity Design'
        },
        {
          image: require('../../../assets/images/page-img/43.jpg'),
          name: 'Marketing',
          description: 'Accounting'
        },
        {
          image: require('../../../assets/images/page-img/44.jpg'),
          name: 'Web Developer',
          description: 'uniq Concept'
        },
        {
          image: require('../../../assets/images/page-img/45.jpg'),
          name: 'Latest News',
          description: 'Line information'
        }
      ],
      recentPages: [
        {
          image: require('../../../assets/images/page-img/46.jpg'),
          name: 'Food town'
        },
        {
          image: require('../../../assets/images/page-img/47.jpg'),
          name: 'Touro Univercity'
        },
        {
          image: require('../../../assets/images/page-img/48.jpg'),
          name: 'Moviehouse & Eatery'
        },
        {
          image: require('../../../assets/images/page-img/49.jpg'),
          name: 'Coffee + Crisp'
        }
      ]
    }
  },
  methods: {
    deletePostApi(postId){
      var parent = this;
       const config = {
          method: "post",
          url: "deletepost",
          data:{
            id:postId,
          },
      };
        axios(config).then(res => {
          parent.socialPosts = parent.socialPosts.filter(socialPost =>  socialPost.id !== postId  )

        }).catch(err => {
            console.log(err);
        })
      
    },
    deleteCommentApi(commentId){
      console.log(commentId)
      var parent = this;

        const config = {
            method: "post",
            url: "deletecomment",
            data:{
              id:commentId,
            },
        };


        axios(config).then(res => {

          // parent.socialComments = parent.socialPosts.comments.filter(comment =>  comment.id !== commentId)

          parent.socialPosts.map((socialPost)=> {
            socialPost.comments = socialPost.comments.filter(comment =>  comment.id !== commentId)
          })

        }).catch(err => {
            console.log(err.response);
        })
        
      
    },
   async addPost (post) {
    

      var parent  = this
      const config = {
          method: "post",
          url: "createpost",
          data:post,
          headers: { 'content-type': 'multipart/form-data' }
      };
  
        await axios(config).then(res => {
         
          parent.socialPosts.unshift(res.data.post)
          this.page += 1;
          // this.$root.$emit('user', res.data.user);
          // this.$router.push('/')
        }).catch(err => {
            console.log(err.response);
            // if(typeof err.response.data !== 'undefined' && err.response.data ){
            //   alert('Caption is required');
            // }
        })
    },
    filterFun(cat){
      this.page = 1;
      this.socialPosts = [];
      this.infiniteId += 1;
      this.cat = cat;
      this.allLoadError = ''
    },
    
    async infiniteHandler($state) {
       
      var parent = this
      var config = {
      method: "get",
      url: 'tips',
      params: {
          cat:parent.cat,
          page: parent.page,
      },
    };

      if(parent.page <= parent.last_page){
        await axios(config).then((response) => {
          parent.last_page = response.data.postpaginate.last_page;
         

          
          if (parent.page <= response.data.postpaginate.last_page) {
            
            if (response.data.posts.length) {
                parent.page += 1;
                parent.socialPosts.push(...response.data.posts)
                $state.loaded();
            } else{
              parent.allLoadError = '(: Not Found '
            }
            // this.list.push(...data.hits);
            // parent.socialPosts.push(...response.data.posts)
            // $state.loaded();
          } else {
            $state.complete();
          }
        }).catch( err => {
          console.log(err)
          
        });
      }else{
        this.allLoadError = '(: Not Found '
      }
    
      
    },
  }
}
</script>

<template>

    <div class="row">
         <div class="col-md-12">
            <h4 class="card-title">About User</h4>              
           <br>
         </div>
         
        <div class="col-md-6">
         
            <div class="row">
              <div class="col-6" v-if="user.name">
                <h6>Name</h6>
              </div>
              <div class="col-6"  v-if="user.name">
                <p class="mb-0">{{user.name}}</p>
              </div>
             
              <div class="col-6"  v-if="user.designation">
                <h6>Designation</h6>
              </div>
              <div class="col-6"  v-if="user.designation">
                <p class="mb-0">{{user.designation}}</p>
              </div>
             
              <div class="col-6" v-if="user.working_since">
                <h6>Working Since</h6>
              </div>
              <div class="col-6" v-if="user.working_since">
                <p class="mb-0">{{user.working_since}}</p>
              </div>
           
               <div class="col-6" v-if="user.Interests">
                <h6>Interests</h6>
              </div>
              <div class="col-6" v-if="user.Interests">
                <p class="mb-0">{{user.Interests}}</p>
              </div>
            
              <div class="col-6" v-if="user.email">
                <h6>Email</h6>
              </div>
              <div class="col-6" v-if="user.email">
                <p class="mb-0">{{user.email}}</p>
              </div>

              <div class="col-6" v-if="user.personalEmail">
                <h6>Personal Email</h6>
              </div>
              <div class="col-6" v-if="user.personalEmail">
                <p class="mb-0">{{user.personalEmail}}</p>
              </div>
            
              <div class="col-6" v-if="user.country">
                <h6>Country/City</h6>
              </div>
              <div class="col-6" v-if="user.country">
                <p class="mb-0">{{user.country}}</p>
              </div>
            
              <div class="col-6" v-if="user.timezone">
                <h6>Timezone</h6>
              </div>
              <div class="col-6" v-if="user.timezone">
                <p class="mb-0">{{user.timezone}}</p>
              </div>
               
              <div class="col-6" v-if="user.about_me">
                <h6>About Me</h6>
              </div>
              <div class="col-6" v-if="user.about_me">
                <p class="mb-0">{{user.about_me}}</p>
              </div>  
             </div>           
        </div>
         <div class="col-md-6">
  <div class="row">
              <div class="col-6" v-if="user.prefer_to_be_called">
                <h6>Prefer to be called</h6>
              </div>
              <div class="col-6" v-if="user.prefer_to_be_called">
                <p class="mb-0">{{user.prefer_to_be_called}}</p>
              </div>
             
               <div class="col-6" v-if="user.platform">
                <h6>Platform</h6>
              </div>
              <div class="col-6" v-if="user.platform">
                <p class="mb-0">{{user.platform}}</p>
              </div>
             
              <div class="col-6" v-if="user.birthday">
                <h6>Birthday</h6>
              </div>
              <div class="col-6" v-if="user.birthday">
                <p class="mb-0">{{user.birthday}}</p>
              </div>
              
               <div class="col-6" v-if="user.lang">
                <h6>Languages Known</h6>
              </div>
              <div class="col-6" v-if="user.lang">
                <p class="mb-0">{{user.lang}}</p>
              </div>
              
              <div class="col-6" v-if="user.phone">
                <h6>Phone</h6>
              </div>
              <div class="col-6" v-if="user.phone">
                <p class="mb-0">{{user.phone}}</p>
              </div>
           
              <div class="col-6" v-if="user.working_hours">
                <h6>Working Hours</h6>
              </div>
              <div class="col-6" v-if="user.working_hours">
                <p class="mb-0">{{user.working_hours}}</p>
              </div>
              
              <div class="col-6" v-if="user.comment">
                <h6>Comment</h6>
              </div>
              <div class="col-6 mini-scroll" v-if="user.comment">
                <p class="mb-0">{{user.comment}}</p>
              </div>      
             
             </div>     

         </div>
    </div>
</template>
<script>
import { socialvue } from '../../../../config/pluginInit'
export default {
  name: 'About',
  mounted () {
    socialvue.index()
    var userData =  JSON.parse(localStorage.getItem('user'));
    const userOtherData = JSON.parse(userData.user_data);
    this.user.name = userData.name
    this.user.designation = userOtherData.designation
    this.user.working_since = userOtherData.working_since
    this.user.Interests = userOtherData.Interests
    this.user.email = userData.email
    this.user.country = userOtherData.country
    this.user.timezone = userOtherData.timezone
    this.user.about_me = userOtherData.about_me
    this.user.prefer_to_be_called = userOtherData.prefer_to_be_called
    this.user.platform = userOtherData.platform
    this.user.birthday = userData.birthday
    this.user.lang = userOtherData.lang
    this.user.phone = userData.phone
    this.user.working_hours = userOtherData.working_hours
    this.user.comment = userOtherData.comment
    this.user.personalEmail = userOtherData.personalEmail
  },
  components: {
  },
  data () {
    return {
      user:{
        name:'',
        designation:'',
        working_since:'',
        Interests:'',
        email:'',
        country:'',
        timezone:'',
        about_me:'',
        prefer_to_be_called:'',
        platform:'',
        birthday:"",
        lang:'',
        phone:'',
        working_hours:'',
        comment:'',
        personalEmail:''
      },
      friend: [
        {
          img: require('../../../../assets/images/user/01.jpg'),
          name: 'Paul Molive',
          realtion: 'Brothe'
        },
        {
          img: require('../../../../assets/images/user/02.jpg'),
          name: 'Anna Mull',
          realtion: 'Sister'
        },
        {
          img: require('../../../../assets/images/user/03.jpg'),
          name: 'Paige Turner',
          realtion: 'Cousin'
        }
      ],
      work: [
        {
          img: require('../../../../assets/images/user/01.jpg'),
          name: 'Themeforest',
          des: 'Web Designer'
        },
        {
          img: require('../../../../assets/images/user/02.jpg'),
          name: 'iqonicdesign',
          des: 'Web Developer'
        },
        {
          img: require('../../../../assets/images/user/03.jpg'),
          name: 'W3school',
          des: 'Designer'
        }
      ]
    }
  }

}
</script>
